var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-log pa-5"},_vm._l((_vm.formattedChatData.formattedChatLog),function(msgGrp,index){return _c('div',{key:msgGrp.senderId + String(index),staticClass:"chat-group d-flex align-start",class:[
            { 'flex-row-reverse': msgGrp.senderId !== _vm.formattedChatData.contact.id },
            { 'mb-8': _vm.formattedChatData.formattedChatLog.length - 1 !== index } ]},[_c('div',{staticClass:"chat-avatar",class:msgGrp.senderId !== _vm.formattedChatData.contact.id ? 'ms-4' : 'me-4'},[_c('v-avatar',{attrs:{"size":"38"}},[_c('v-img',{attrs:{"src":msgGrp.senderId === _vm.formattedChatData.contact.id
                            ? _vm.formattedChatData.contact.avatar
                            : _vm.profileUserAvatar}})],1)],1),_c('div',{staticClass:"chat-body d-inline-flex flex-column",class:msgGrp.senderId !== _vm.formattedChatData.contact.id ? 'align-end' : 'align-start'},[_vm._l((msgGrp.messages),function(msgData,msgIndex){return _c('p',{key:msgData.time,staticClass:"chat-content py-3 px-4 elevation-1",class:[
                    msgGrp.senderId === _vm.formattedChatData.contact.id
                        ? 'bg-card chat-left'
                        : 'primary white--text chat-right',
                    msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1' ]},[_vm._v(" "+_vm._s(msgData.msg)+" ")])}),_c('div',{class:{ 'text-right': msgGrp.senderId !== _vm.formattedChatData.contact.id }},[_c('span',{staticClass:"text-xs me-1 text--disabled"},[_vm._v(_vm._s(_vm.formatDate(msgGrp.messages[msgGrp.messages.length - 1].time, { hour: 'numeric', minute: 'numeric', })))]),(msgGrp.senderId !== _vm.formattedChatData.contact.id)?_c('v-icon',{attrs:{"size":"16","color":_vm.resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).color}},[_vm._v(" "+_vm._s(_vm.resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).icon)+" ")]):_vm._e()],1)],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }